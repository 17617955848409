@mixin input-style-from-css-vars() {
  @apply inline-flex flex-row
    w-full h-interaction
    py-2 px-3
    transition-all ease-out duration-100
    bg-neutral-primary
    border border-interaction rounded-interaction
    hover:border-neutral-secondary
    focus-within:border-brand-secondary
    focus-within:ring-1
    focus-within:ring-brand-secondary
    focus-within:hover:border-brand-secondary;
}

@mixin input-focus-style-from-css-vars() {
  @apply border-brand-secondary ring-1 ring-brand-secondary;
}

@mixin input-invalid-style-from-css-vars() {
  @apply border-error ring-0;
}

@mixin input-disabled-style-from-css-vars() {
  @apply border-error ring-0 pointer-events-none;
}

@mixin input-active-style-from-css-vars() {
  @apply border-brand-primary;
}

@mixin placeholder-style-from-css-vars() {
  @apply text-neutral-subtle select-none;
}
