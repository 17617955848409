@use 'sass:map';
@use '../mixins/css-custom-props.mixins' as css-props;
@use '../mixins/typography.mixins' as typography-utils;
@use '../mixins/sass-utils.mixins' as utils;

$font-family--native:
  system-ui,
  BlinkMacSystemFont,
  -apple-system,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Fira Sans,
  Droid Sans,
  Helvetica Neue,
  sans-serif !default;

/// List of css properties that belong to a typography level
$font-css-properties: (
  'font-family',
  'font-size',
  'font-weight',
  'line-height',
  'letter-spacing',
  'text-transform'
) !default;

$text-size-variants: (
  'xs',
  'sm',
  'base',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl'
) !default;

$-default-config: (
  // Display variants
  display-lg: (),
  display-md: (),
  display-sm: (),
  // Headline variants
  headline-1: (),
  headline-2: (),
  headline-3: (),
  headline-4: (),
  headline-5: (),
  headline-6: (),
  // Title variants
  title-md: (),
  subtitle-md: (),
  overline-md: (),
  // Body variants
  body-lg: (),
  body-md: (),
  body-sm: (),
  caption-md: (),
  // Form elements
  label-md: (),
  label-sm: (),
  button-lg: (),
  button-md: (),
  button-sm: ()
) !default;

// ------------------------------------------------------------
// General
// ------------------------------------------------------------
/// Emits common typography variables that are theme-unrelated.
@mixin common-css-vars() {
  // text-2xs
  --fmnts-text-2xs--font-size: 0.75rem;
  --fmnts-text-2xs--font-weight: 400;
  --fmnts-text-2xs--line-height: 1rem;
  // text-xs
  --fmnts-text-xs--font-size: 0.79rem;
  --fmnts-text-xs--font-weight: 400;
  --fmnts-text-xs--line-height: 1.2;
  // text-sm
  --fmnts-text-sm--font-size: 0.889rem;
  --fmnts-text-sm--font-weight: 400;
  --fmnts-text-sm--line-height: 1.2;
  // text-md
  --fmnts-text-md--font-size: 1rem;
  --fmnts-text-md--line-height: 1.5rem;
  // text-lg
  --fmnts-text-lg--font-size: 1.125rem;
  --fmnts-text-lg--line-height: 1.75rem;
  // text-xl
  --fmnts-text-xl--font-size: 1.25rem;
  --fmnts-text-xl--line-height: 1.75rem;
  // text-2xl
  --fmnts-text-2xl--font-size: 1.5rem;
  --fmnts-text-2xl--line-height: 2rem;
  // text-3xl
  --fmnts-text-3xl--font-size: 1.875rem;
  --fmnts-text-3xl--line-height: 2.25rem;
  // text-4xl
  --fmnts-text-4xl--font-size: 2.25rem;
  --fmnts-text-4xl--line-height: 2.5rem;
  // text-5xl
  --fmnts-text-5xl--font-size: 3rem;
  --fmnts-text-5xl--line-height: 1.15;

  // Display variants
  --fmnts-display-lg--font-size: 5rem;
  --fmnts-display-lg--line-height: 1.15;
  --fmnts-display-md--font-size: 4rem;
  --fmnts-display-md--line-height: 1.15;
  --fmnts-display-sm--font-size: var(--fmnts-text-5xl--font-size);
  --fmnts-display-sm--line-height: var(--fmnts-text-5xl--line-height);

  // Headings
  --fmnts-headline--margin-top: 0;
  --fmnts-headline--margin-bottom: 0; // It's best to never have a margin here
  --fmnts-headline--font-weight: 500;
  --fmnts-headline-1--font-size: var(--fmnts-text-4xl--font-size);
  --fmnts-headline-1--font-weight: var(--fmnts-headline--font-weight);
  --fmnts-headline-1--line-height: var(--fmnts-text-4xl--line-height);
  --fmnts-headline-2--font-size: var(--fmnts-text-3xl--font-size);
  --fmnts-headline-2--font-weight: var(--fmnts-headline--font-weight);
  --fmnts-headline-2--line-height: var(--fmnts-text-3xl--line-height);
  --fmnts-headline-3--font-size: var(--fmnts-text-2xl--font-size);
  --fmnts-headline-3--font-weight: var(--fmnts-headline--font-weight);
  --fmnts-headline-3--line-height: var(--fmnts-text-2xl--line-height);
  --fmnts-headline-4--font-size: var(--fmnts-text-xl--font-size);
  --fmnts-headline-4--font-weight: var(--fmnts-headline--font-weight);
  --fmnts-headline-4--line-height: var(--fmnts-text-xl--line-height);
  --fmnts-headline-5--font-size: var(--fmnts-text-lg--font-size);
  --fmnts-headline-5--line-height: var(--fmnts-text-lg--line-height);
  --fmnts-headline-5--font-weight: var(--fmnts-headline--font-weight);
  --fmnts-headline-6--font-size: var(--fmnts-text-md--font-size);
  --fmnts-headline-6--line-height: var(--fmnts-text-md--line-height);
  --fmnts-headline-6--font-weight: var(--fmnts-headline--font-weight);

  // Title variants
  @include level-from-existing-vars('fmnts-title-lg', 'fmnts-text-xl');
  --fmnts-title-lg--font-weight: 700;
  @include level-from-existing-vars('fmnts-title-md', 'fmnts-text-lg');
  --fmnts-title-md--font-weight: 600;
  @include level-from-existing-vars('fmnts-subtitle-md', 'fmnts-text-sm');
  @include level-from-existing-vars('fmnts-overline-md', 'fmnts-text-sm');
  --fmnts-overline-md--text-transform: uppercase;
  --fmnts-overline-md--letter-spacing: 0.3em;
  @include level-from-existing-vars('fmnts-footer-md', 'fmnts-text-sm');
  // Body
  --fmnts-body-lg--font-size: var(--fmnts-text-lg--font-size);
  --fmnts-body-lg--line-height: var(--fmnts-text-lg--line-height);
  --fmnts-body-md--font-size: var(--fmnts-text-md--font-size);
  --fmnts-body-md--line-height: var(--fmnts-text-md--line-height);
  --fmnts-body-sm--font-size: var(--fmnts-text-sm--font-size);
  --fmnts-body-sm--line-height: var(--fmnts-text-sm--line-height);

  --fmnts-paragraph-md--margin-top: 0;
  --fmnts-paragraph-md--margin-bottom: 1rem;
  --fmnts-paragraph-sm--margin-top: 0;
  --fmnts-paragraph-sm--margin-bottom: 0.75rem;
}

/// Emits typography styling for common native HTML tags.
@mixin native-html() {
  p {
    @include from-css-vars(fmnts-paragraph-md);
    margin-top: var(--fmnts-paragraph-md--margin-top);
    margin-bottom: var(--fmnts-paragraph-md--margin-bottom);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--fmnts-headline--margin-top);
    margin-bottom: var(--fmnts-headline--margin-bottom);
  }

  h1 {
    @include from-css-vars(fmnts-headline-1);
  }

  h2 {
    @include from-css-vars(fmnts-headline-2);
  }

  h3 {
    @include from-css-vars(fmnts-headline-3);
  }

  h4 {
    @include from-css-vars(fmnts-headline-4);
  }

  h5 {
    @include from-css-vars(fmnts-headline-5);
  }

  h6 {
    @include from-css-vars(fmnts-headline-6);
  }

  caption,
  small {
    @include from-css-vars(fmnts-caption-md);
  }
}

// ------------------------------------------------------------
// Applying a theme
// ------------------------------------------------------------
/// Applies typography styling from CSS variables
/// by a shared `$element`.
@mixin from-css-vars($element) {
  @each $font-var in $font-css-properties {
    #{$font-var}: var(--#{$element}--#{$font-var});
  }
}

/// Creates CSS variables for each font css property
/// defined in the map $level and prefixes each
/// variable with the given $element.
@mixin level-with-css-vars(
  $element,
  $font-family: false,
  $font-size: false,
  $font-weight: false,
  $line-height: false,
  $letter-spacing: false,
  $text-transform: false
) {
  $level: (
    font-family: $font-family,
    font-size: $font-size,
    font-weight: $font-weight,
    line-height: $line-height,
    letter-spacing: $letter-spacing,
    text-transform: $text-transform,
  );

  @each $font-var in $font-css-properties {
    $value: map.get($level, $font-var);
    @if $value != null and $value != false {
      --#{$element}--#{$font-var}: #{$value};
    }
  }
}

/// Emits CSS variables for an element and uses the
/// values from another element as the defaults.
/// @param {String} $element Name of the element for which to create CSS variables
/// @param {List} $inherit-from Name of the element from which the values should be inherited
/// @param {String} $font-size Fallback value for font size
/// @param {String | Number} $line-height Fallback value for line height
/// @param {String | Number} $font-weight Fallback value for font weight
/// @param {String} $font-family Fallback value for font family
/// @param {String | Number} $letter-spacing Fallback value for letter spacing
/// @param {String | Number} $text-transform Fallback value for text transform
@mixin level-from-existing-vars(
  $element,
  $inherit-from,
  $font-size: false,
  $line-height: false,
  $font-weight: false,
  $font-family: false,
  $letter-spacing: false,
  $text-transform: false
) {
  @include level-with-css-vars(
    $element,
    $font-family: _combine-to-css-var($inherit-from, font-family, $font-family),
    $font-size: _combine-to-css-var($inherit-from, font-size, $font-size),
    $font-weight: _combine-to-css-var($inherit-from, font-weight, $font-weight),
    $line-height: _combine-to-css-var($inherit-from, line-height, $line-height),
    $letter-spacing: _combine-to-css-var(
        $inherit-from,
        letter-spacing,
        $letter-spacing
      ),
    $text-transform: _combine-to-css-var(
        $inherit-from,
        text-transform,
        $text-transform
      )
  );
}

// ------------------------------------------------------------
// Create a theme
// ------------------------------------------------------------
/// Defines a typography level from the Material Design spec.
/// @param {String} $font-size The font-size for this level.
/// @param {String | Number} $line-height The line-height for this level.
/// @param {String | Number} $font-weight The font-weight for this level.
/// @param {String} $font-family The font-family for this level.
/// @param {String} $letter-spacing The letter-spacing for this level.
/// @param {String} $text-transform The text-transform for this level.
/// @returns {Map} A map representing the definition of this typographic level.
@function level-from-config(
  $font-size: false,
  $line-height: false,
  $font-weight: false,
  $font-family: false,
  $letter-spacing: false,
  $text-transform: false
) {
  @return utils.filter-value-from-map(
    false,
    (
      font-size: $font-size,
      line-height: $line-height,
      font-weight: $font-weight,
      font-family: $font-family,
      letter-spacing: $letter-spacing,
      text-transform: $text-transform,
    )
  );
}

/// Defines the typography configuration for a theme.
/// @returns {Map} A map representing the typography configuration for a theme.
@function config(
  $font-family: 'inherit',
  $display-lg: null,
  $display-md: null,
  $display-sm: null,
  $headline-1: null,
  $headline-2: null,
  $headline-3: null,
  $headline-4: null,
  $headline-5: null,
  $headline-6: null,
  $title-md: null,
  $subtitle-md: null,
  $overline-md: null,
  $body-lg: null,
  $body-md: null,
  $body-sm: null,
  $caption-md: null,
  $label-md: null,
  $label-sm: null,
  $button-lg: null,
  $button-md: null,
  $button-sm: null
) {
  @return _apply-font-family(
    $font-family,
    (
      // Display variants
      display-lg: $display-lg or _get-typography-default-level(display-lg),
      display-md: $display-md or _get-typography-default-level(display-md),
      display-sm: $display-sm or _get-typography-default-level(display-sm),
      // Headline variants
      headline-1: $headline-1 or _get-typography-default-level(headline-1),
      headline-2: $headline-2 or _get-typography-default-level(headline-2),
      headline-3: $headline-3 or _get-typography-default-level(headline-3),
      headline-4: $headline-4 or _get-typography-default-level(headline-4),
      headline-5: $headline-5 or _get-typography-default-level(headline-5),
      headline-6: $headline-6 or _get-typography-default-level(headline-6),
      // Title variants
      title-md: $title-md or _get-typography-default-level(title-md),
      subtitle-md: $subtitle-md or _get-typography-default-level(subtitle-md),
      overline-md: $overline-md or _get-typography-default-level(overline-md),
      // Body variants
      body-lg: $body-lg or _get-typography-default-level(body-lg),
      body-md: $body-md or _get-typography-default-level(body-md),
      body-sm: $body-sm or _get-typography-default-level(body-sm),
      caption-md: $caption-md or _get-typography-default-level(caption-md),
      // Form elements
      label-md: $label-md or _get-typography-default-level(label-md),
      label-sm: $label-sm or _get-typography-default-level(label-sm),
      button--lg: $button-lg or _get-typography-default-level(button-lg),
      button--md: $button-md or _get-typography-default-level(button-md),
      button--sm: $button-sm or _get-typography-default-level(button-sm)
    )
  );
}

/// Emits the CSS variables for the typography configuration for a theme.
/// @param {Map} $theme A theme.
@mixin css-vars-from-theme($theme) {
  $config: map.get($theme, 'typography');

  @each $level, $levelConfig in $config {
    @include level-with-css-vars(
      $element: fmnts-#{$level},
      // $font-family: font-family($config, $level),
      $font-family: _get-typography-level-value-from-theme(
          $config,
          $level,
          'font-family'
        ),
      // $font-size: font-size($config, $level),
      $font-size: _get-typography-level-value-from-theme(
          $config,
          $level,
          'font-size'
        ),
      // $font-weight: font-weight($config, $level),
      $font-weight: _get-typography-level-value-from-theme(
          $config,
          $level,
          'font-weight'
        ),
      // $line-height: line-height($config, $level),
      $line-height: _get-typography-level-value-from-theme(
          $config,
          $level,
          'line-height'
        ),
      $letter-spacing: _get-typography-level-value-from-theme(
          $config,
          $level,
          'letter-spacing'
        ),
      $text-transform: _get-typography-level-value-from-theme(
          $config,
          $level,
          'text-transform'
        )
    );
  }
}

// ------------------------------------------------------------
// Helpers
// ------------------------------------------------------------
@function _get-typography-default-level($level) {
  @return map.get($-default-config, $level);
}

// Applies the default font family to all levels in a typography config.
@function _apply-font-family($font-family, $initial-config) {
  $config: $initial-config;

  // Sass maps are immutable so we have to re-assign the variable each time.
  @each $key, $level in $config {
    @if map.has-key($level, 'font-family') == false {
      $config: map.set(
        $config,
        $key,
        map.set($level, 'font-family', $font-family)
      );
    }
  }

  @return $config;
}

@function _get-typography-level-value-from-theme($config, $levelName, $value) {
  @return map.get(map.get($config, $levelName), $value);
}

@function _combine-to-css-var($inherit-from, $name, $default: false) {
  @return css-props.value-from-vars(
    css-props.combine-all($inherit-from, $name),
    $default
  );
}
