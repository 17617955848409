@use '../../../core/mixins/input.mixins' as input-field;

@mixin base($theme) {
  .fmnts-input {
    // remove default html input styles.
    @apply bg-transparent p-0 border-none appearance-none outline-none
     typo-body typo-body-base
     w-full;

    &:focus {
      outline: 0;
    }

    &::placeholder {
      @include input-field.placeholder-style-from-css-vars();
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    &.ng-touched.ng-invalid {
      @include input-field.input-invalid-style-from-css-vars();
    }

    &:disabled,
    &.disabled,
    &[disabled],
    &[readonly] {
      @apply pointer-events-none;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
  }

  .fmnts-input-form-field-textarea-control {
    vertical-align: middle;
    resize: vertical;
    box-sizing: border-box;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    overflow: auto;
  }
}

@mixin color($theme) {
}

@mixin typography($theme) {
}

@mixin theme($theme) {
  @include base($theme);
  @include color($theme);
  @include typography($theme);
}
