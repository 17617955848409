@layer components {
  .fmnts-text-input__prefix,
  .fmnts-text-input__suffix {
    @apply flex flex-row flex-none items-center self-stretch space-x-2;
  }

  .fmnts-text-input__prefix {
    @apply mr-2 empty:mr-0;
  }
  .fmnts-text-input__suffix {
    @apply ml-2 empty:ml-0;
  }
  .fmnts-text-input__infix {
    @apply inline-flex flex-auto justify-center overflow-hidden;
  }

  fa-icon {
    &.fmnts-text-input-prefix-item,
    &.fmnts-text-input-suffix-item {
      @apply text-icon-neutral-secondary leading-none;

      .fmnts-form-field--disabled & {
        @apply text-icon-neutral-subtle;
      }
    }
  }

  .fmnts-text-input-suffix-item.fmnts-icon-button {
    @apply last:-mr-2;
  }
}
